export enum FreightStatus {
	published = 'Published',
	archived = 'Archived',
	notSigned = 'NotSigned',
	dispatched = 'Dispatched',
	pickedUp = 'PickedUp',
	delivered = 'Delivered',
	cancelled = 'Cancelled',
}
export const FreightStatusObject: any = {
	Published: 'Publicado',
	Archived: 'Archivado',
	NotSigned: 'No firmado',
	Dispatched: 'Enviado',
	PickedUp: 'Levantado',
	Delivered: 'Entregado',
	Cancelled: 'Cancelado',
};
